@if (lot(); as lot) {
  @if (isMobileView(); as isMobileView) {
    <div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-600">
      <div class="tw-flex tw-justify-between tw-pb-4 tw-pt-2">
        @if (isPanelOpen()) {
          <mat-icon
            class="tw-rotate-180 tw-text-gray-400"
            svgIcon="arrow-right-line"
            (click)="isPanelOpen.set(null)"
          >
          </mat-icon>
        }
        <div class="tw-font-medium tw-text-xl tw-text-gray-100">
          @if (isPanelOpen() === 'info') {
            {{ 'P2P_WIDGETS.SIH_TRADE.USER_INFO' | translate }}
          } @else if (isPanelOpen() === 'inventory') {
            {{ 'P2P_WIDGETS.SIH_TRADE.INVENTORY' | translate }}
          } @else {
            {{ 'P2P_WIDGETS.SIH_TRADE.ASSEMBLE' | translate }}
          }
        </div>
        <mat-icon
          class="tw-text-gray-400"
          svgIcon="close"
          (click)="closeModal.emit()"
        ></mat-icon>
      </div>
      @if (isPanelOpen() !== 'inventory') {
        @if (isPanelOpen() !== 'info') {
          <div
            class="tw-flex tw-justify-between tw-items-center tw-rounded-xl tw-p-3 tw-bg-gray-650 tw-mb-6 tw-font-normal tw-text-xs tw-text-gray-400"
          >
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_WILL_GET' | translate }}
            <app-currency [value]="lot.sellerPrice"></app-currency>
          </div>
          <app-trade-items-list
            class="tw-pb-6 tw-min-h-[40vh]"
            [items]="lot.items"
            [currentItem]="currentSteamItem()"
            [isMobileView]="isMobileView"
            (openInventoryPanel)="isPanelOpen.set('inventory')"
            (setItemActive)="setActiveItemById($event); isPanelOpen.set('inventory')"
          ></app-trade-items-list>
        } @else {
          <app-trade-info-panel
            class="tw-pb-9"
            [lot]="lot"
          ></app-trade-info-panel>
        }
        <app-trade-modal-actions
          class="tw-block tw-mt-auto"
          [depositingItem]="lot"
          [offerTimer]="offerTimer()"
          [isMobileView]="isMobileView"
          (closeModal)="closeModal.emit()"
          (openAutoTradeTab)="openAutoTradeTab.emit()"
          (openTradeInfo)="isPanelOpen.set(isPanelOpen() ? null : 'info')"
        ></app-trade-modal-actions>
      } @else {
        @if (currentSteamItem(); as currentSteamItem) {
          <app-trade-items-list
            class="tw-pb-3 tw-min-h-[9rem]"
            [items]="lot.items"
            [direction]="'horizontal'"
            [currentItem]="currentSteamItem"
            (setItemActive)="setActiveItemById($event)"
          ></app-trade-items-list>
          <app-steam-inventory-position
            class="tw-pb-3 tw-flex tw-justify-center"
            [currentSteamItem]="currentSteamItem"
            [currentSteamInventoryPage]="allowedPagesDict[currentSteamItem.page]"
            [pages]="visiblePagesArray"
            (setItemActive)="setActiveItemById($event)"
            (setPageActive)="setActiveFirstItemOnPage(allowedPagesDict[$event])"
          ></app-steam-inventory-position>
        }
      }
    </div>
  } @else {
    <div class="tw-flex tw-relative">
      <div class="tw-flex tw-flex-col tw-bg-gray-600 tw-min-w-96 tw-p-6">
        <span class="tw-font-medium tw-text-xl tw-text-gray-100 tw-pb-4">{{ 'P2P_WIDGETS.SIH_TRADE.ASSEMBLE' | translate }}</span>
        <div
          class="tw-flex tw-justify-between tw-items-center tw-rounded-xl tw-p-3 tw-bg-gray-650 tw-mb-6 tw-font-normal tw-text-xs tw-text-gray-400"
        >
          {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_WILL_GET' | translate }}
          <app-currency [value]="lot.sellerPrice"></app-currency>
        </div>
        <app-trade-items-list
          class="tw-pb-6 tw-h-full"
          [items]="lot.items"
          [currentItem]="currentSteamItem()"
          (setItemActive)="setActiveItemById($event)"
        ></app-trade-items-list>
        <app-trade-modal-actions
          class="tw-block tw-mt-auto"
          [depositingItem]="lot"
          [offerTimer]="offerTimer()"
          (closeModal)="closeModal.emit()"
          (openAutoTradeTab)="openAutoTradeTab.emit()"
        ></app-trade-modal-actions>
      </div>
      <div class="inventory tw-flex tw-flex-col tw-bg-gray-650 tw-w-min tw-p-6 tw-relative">
        @if (currentSteamItem(); as currentSteamItem) {
          <div class="tw-font-normal tw-text-xs tw-text-gray-400 tw-pb-4">
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_HEADER' | translate }}
          </div>
          <app-steam-inventory-position
            class="tw-pb-3"
            [currentSteamItem]="currentSteamItem"
            [currentSteamInventoryPage]="allowedPagesDict[currentSteamItem.page]"
            [pages]="visiblePagesArray"
            (setItemActive)="setActiveItemById($event)"
            (setPageActive)="setActiveFirstItemOnPage(allowedPagesDict[$event])"
          ></app-steam-inventory-position>
        }
        <app-trade-info-panel
          class="tw-w-min"
          [lot]="lot"
        ></app-trade-info-panel>
      </div>
      <div
        class="tw-absolute tw-top-[1%] tw-right-[1%] tw-text-gray-400 hover:tw-text-gray-300 tw-cursor-pointer"
        (click)="closeModal.emit()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </div>
      <!-- TODO временный блок на пару дней. Удалить потом -->
      @if (!warningSeen()) {
        <div class="auction-warning">
          <div class="auction-warning__title">
            {{ 'P2P_WIDGETS.DEPOSITING.WARNING_MODAL.WARNING' | translate }}
          </div>
          <div class="auction-warning__message">
            {{ 'P2P_WIDGETS.DEPOSITING.WARNING_MODAL.WARNING_TEXT' | translate }}
          </div>
          <button
            class="btn btn-primary--design auction-warning__action"
            (click)="onCloseWarning()"
          >
            {{ 'LOCAL.CASES.MODAL.JOKER.BUTTON' | translate }}
          </button>
        </div>
      }
    </div>
  }
}
