import { inject, Injectable } from '@angular/core';
import type { NavigationExtras, Params } from '@angular/router';
import type { IError, IPromoCode, IPromocodeData, IPromocodeReward } from '@dev-fast/types';
import { PromocodeTypes, PromoStatus, ReferralCampaignTypes, StateActionStatus } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, ofActionDispatched, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import type { Observable } from 'rxjs';
import { debounceTime, map, merge, startWith } from 'rxjs';

import { ActivatePromoCode, GetCurrentPromo, ReferralsState, RemoveActivationErr } from '@app/core/state/referrals';
import type { PromocodeEngine } from '@app/widgets/promocode';

@Injectable()
export class PromocodeService implements PromocodeEngine {
  readonly #actions$ = inject(Actions);
  @Select(ReferralsState.activationErr) readonly promocodeError$!: Observable<IError | null>;
  @Select(ReferralsState.activePromoCode) readonly promocode$!: Observable<IPromoCode | null>;
  @Select(ReferralsState.promoData) readonly promoData$!: Observable<IPromocodeData>;

  /* для дизейбла кнопки после активации */
  readonly applyActionStatus$: Observable<StateActionStatus.DISPATCH | StateActionStatus.SUCCESS> = merge(
    this.#actions$.pipe(
      ofActionDispatched(ActivatePromoCode),
      map(() => StateActionStatus.DISPATCH),
    ),
    this.#actions$.pipe(
      ofActionCompleted(ActivatePromoCode),
      debounceTime(1000),
      map(() => StateActionStatus.SUCCESS),
    ),
  ).pipe(
    startWith(StateActionStatus.SUCCESS),
    map((val) => {
      return val as StateActionStatus.DISPATCH | StateActionStatus.SUCCESS;
    }),
  );

  readonly promocodeReward$: Observable<IPromocodeReward | null> = this.promocode$.pipe(
    map((promocode) => this.#rewardConstructor(promocode)),
  );

  readonly promocodeStatus$: Observable<PromoStatus> = this.promoData$.pipe(
    map((data: IPromocodeData) => {
      return this.#promoStatusChecker(data);
    }),
  );

  @Dispatch() activatePromoCode = (code: string): ActivatePromoCode => new ActivatePromoCode(code);
  @Dispatch() getCurrentPromo = (): GetCurrentPromo => new GetCurrentPromo();
  @Dispatch()
  navigateTo(path: any[], queryParams?: Params | undefined, extras?: NavigationExtras | undefined): Navigate {
    return new Navigate(path, queryParams, extras);
  }

  @Dispatch() removeActivationErr = (): RemoveActivationErr => new RemoveActivationErr();

  #rewardConstructor(reward: IPromoCode | null): IPromocodeReward | null {
    if (reward && !reward.rewarded) {
      const rewardValue = {
        type:
          reward.type !== ReferralCampaignTypes.MONEY && reward.type !== PromocodeTypes.MONEY
            ? PromocodeTypes.PERCENT
            : PromocodeTypes.MONEY,
        amount: reward.reward,
      };
      return {
        rewardValue: rewardValue,
        bonusCases: reward.type === ReferralCampaignTypes.CASES || reward.type === ReferralCampaignTypes.GOFAST ? 1 : 0, //мб будет разное кол-во кейсов
        minRewardPayment: 10, //мб будет приходить с для каждого промика свое ограничение
      };
    }
    return null;
  }

  #promoStatusChecker(data: IPromocodeData): PromoStatus {
    if (data.activationErr) {
      return PromoStatus.ERROR;
    }
    if (data.activePromoCode && !data.activePromoCode.rewarded) {
      return PromoStatus.SUCCESS;
    }
    return PromoStatus.DEFAULT;
  }
}
