import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CountdownComponentModule, P2pLotCardModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { AppCurrencyModule, CountdownFormatPipe, ReplacePipeModule } from '@app/shared/pipes';

import { P2pNewAuctionCompletedModalComponent } from '../../modals';
import { P2pSellingItemComponent } from './p2p-selling-item.component';

@NgModule({
  declarations: [P2pSellingItemComponent],
  exports: [P2pSellingItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    CountdownComponentModule,
    MatProgressSpinnerModule,
    P2pNewAuctionCompletedModalComponent,
    ReplacePipeModule,
    P2pLotCardModule,
    CurrencyComponent,
    CountdownFormatPipe,
  ],
})
export class P2pSellingItemModule {}
