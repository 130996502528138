<div class="menu-panel__wrapper">
  @if (activeMenu && activeMenu.length > 0) {
    <div class="menu-panel w-100 tw-font-normal flex fx-column relative">
      @if (activeMenu.includes(menu.MAIN_MENU)) {
        @if (user) {
          <div class="user-info tw-flex tw-flex-col tw-mb-3 tw-pb-6">
            <div class="user-info__header">
              <a
                class="user-info__profile flex fx-align-center gap-2"
                langRouterLink
                [routerLink]="'/account/main'"
                (click)="onMenuNavigation()"
              >
                <app-ui-user-avatar
                  [url]="user.avatar"
                  [userId]="user.id"
                  [noModal]="true"
                ></app-ui-user-avatar>
                <div class="user-info--profile-data flex fx-column">
                  <span class="user-info--profile-data-name tw-font-medium">{{ user.name }}</span>
                  <div
                    class="user-info--profile-data-status font-small"
                    *isProject="{ name: projects.MARKET, exclude: true }"
                  >
                    <span class="status-title">
                      {{ user.profileType | translate | uppercase }} {{ 'ACCOUNT_PREMIUM.ACCOUNT' | translate }}
                    </span>
                  </div>
                </div>
              </a>
              <button
                class="btn-close"
                (click)="closeMenu(menu.MAIN_MENU)"
              >
                <mat-icon svgIcon="close"> </mat-icon>
              </button>
            </div>
            <!--            <div-->
            <!--              class="flex fx-column gap-1"-->
            <!--              *ngIf="user.experience"-->
            <!--            >-->
            <!--              <div class="progressBlock-title flex fx-justify-between fx-align-end font-small">-->
            <!--                <div class="measure">Level {{ user.experience.level }}</div>-->
            <!--                <ng-container *ngIf="!canLevelUp; else levelUpTemplate">-->
            <!--                  <div class="value">XP {{ user.experience.xp }}/{{ user.experience.nextLevelXp }}</div>-->
            <!--                </ng-container>-->
            <!--                <ng-template #levelUpTemplate>-->
            <!--                  <a-->
            <!--                    (click)="onMenuNavigation()"-->
            <!--                    langRouterLink-->
            <!--                    [routerLink]="'account/experience'"-->
            <!--                    class="lvlupBanner flex fx-align-center gap-1 tw-font-black decoration-none"-->
            <!--                  >-->
            <!--                    <mat-icon svgIcon="lvlup-star-design"></mat-icon>-->
            <!--                    <span>{{ 'ACCOUNT_LEVELS.LEVEL_UP' | translate }} </span>-->
            <!--                  </a>-->
            <!--                </ng-template>-->
            <!--              </div>-->
            <!--              <div class="progressBlock-body">-->
            <!--                <div-->
            <!--                  class="progressBar-filler level-progress bar"-->
            <!--                  [style.width.%]="xpProgress"-->
            <!--                ></div>-->
            <!--                <div-->
            <!--                  class="progressBar-blurWrapper"-->
            <!--                  *ngIf="user.experience.xp"-->
            <!--                >-->
            <!--                  <div-->
            <!--                    class="progressBar-blurContainer level-progress"-->
            <!--                    [style.left]="xpBlurPosition"-->
            <!--                  ></div>-->
            <!--                </div>-->
            <!--                <div class="progressBlock-body-background bar w-100"></div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="flex fx-column gap-1">
            <button
              langRouterLink
              [routerLink]="'account/balance'"
              (click)="onMenuNavigation()"
              class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            >
              <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
                <mat-icon svgIcon="bank-card-design"></mat-icon>
                <span>{{ 'BALANCE.BALANCE_TITLE' | translate }}</span>
              </div>
              <app-currency
                class="btn-utility user-balance"
                [value]="userBalance"
              />
            </button>
            <button
              *moduleIsOn="'inventory'"
              [routerLink]="'/inventory'"
              (click)="onMenuNavigation()"
              class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            >
              <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
                <mat-icon svgIcon="brifecase-design"></mat-icon>
                <span>{{ 'BALANCE_HISTORY.INVENTORY' | translate }}</span>
              </div>
              <span class="btn-utility flex fx-align-center items-balance gap-1">
                <mat-icon
                  class="menu-icon"
                  svgIcon="automate-gun"
                ></mat-icon>
                <span>{{ inventoryShortInfo?.inventoryCount }}</span>
              </span>
            </button>
            <a
              *moduleIsOn="'account'; sub: 'referrals'"
              langRouterLink
              [routerLink]="'account/referral'"
              class="btn btn-semi-round btn-transparent-dark--design j-sb w-f decoration-none"
              (click)="onMenuNavigation()"
            >
              <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
                <mat-icon svgIcon="profile-users-design"></mat-icon>
                <span>{{ 'PROFILE.RENEW_SIDE.REFERRAL_PROGRAM' | translate }}</span>
              </div>
            </a>
            <button
              langRouterLink
              [routerLink]="'account/main'"
              (click)="onMenuNavigation()"
              class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            >
              <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
                <mat-icon svgIcon="settings-outline"></mat-icon>
                <span>{{ 'HEADER.COMMON_SETTINGS' | translate }}</span>
              </div>
            </button>
          </div>
        } @else {
          <div class="noauth-header flex fx-align-center fx-justify-between">
            <span>{{ 'HEADER.COMMON_SETTINGS' | translate }}</span>
            <button
              class="btn-close"
              (click)="closeMenu(menu.MAIN_MENU)"
            >
              <mat-icon svgIcon="close"> </mat-icon>
            </button>
          </div>
        }
        <div class="pb-sm-3 flex fx-column border-bottom__main-menu">
          <button
            class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            (click)="openMenu(menu.LANGUAGE)"
          >
            <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
              @if (locale) {
                <img
                  class="language-icon"
                  [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + locale.path.slice(3).toLowerCase() + '.png'"
                  alt
                />
              }
              <span>{{ 'HEADER_SETTINGS.LANGUAGE' | translate }}</span>
            </div>
            <span class="btn-utility submenu-arrow flex fx-align-center">
              <mat-icon
                class="icon lg"
                svgIcon="arrow-left-design"
              ></mat-icon>
            </span>
          </button>
          <button
            class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            langRouterLink
            [routerLink]="'faq'"
            (click)="onMenuNavigation()"
          >
            <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
              <mat-icon [svgIcon]="'faq'"></mat-icon>
              <span>
                <span>{{ 'FAQ' | translate }}</span>
              </span>
            </div>
          </button>
          <button
            class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
            *moduleIsOn="'soundToggler'"
            (click)="onSoundToggle()"
          >
            <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
              <mat-icon [svgIcon]="'volume-' + (soundStatus ? 'on' : 'off') + '-outline-design'"></mat-icon>
              <span>
                <span>{{ 'PROFILE.RENEW_SIDE.SOUND' | translate }}</span>
                <span class="btn-title__subtext"> ({{ (soundStatus ? 'ON' : 'OFF') | translate }}) </span>
              </span>
            </div>
          </button>
          <div class="tw-py-2 tw-px-4">
            <input
              type="range"
              [value]="volumeRange()"
              min="0"
              max="100"
              (input)="changeVolumeRange($event)"
              class="tw-w-full tw-h-2 tw-bg-gray-700 tw-rounded-lg tw-appearance-none tw-cursor-pointer"
              [class.turnOn]="volumeRange()"
              [style.background]="
                'linear-gradient(to right, #00a3ff 4.56%, #01d1ff ' + volumeRange() + '%, #181E21 ' + volumeRange() + '%)'
              "
            />
          </div>
        </div>
        <button
          class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
          (click)="onAuthClick()"
        >
          <div class="btn-title flex fx-align-center gap-2 tw-font-normal">
            @if (!!user) {
              <mat-icon svgIcon="logout-design"></mat-icon>
              <span>{{ 'PROFILE.RENEW_SIDE.EXIT' | translate }}</span>
            } @else {
              <mat-icon svgIcon="login-design"></mat-icon>
              <span>{{ 'LOGIN' | translate }}</span>
            }
          </div>
        </button>
      }
    </div>
  }
</div>
