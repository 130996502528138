import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, input, Output, output, signal } from '@angular/core';
import type { IInventoryShortInfo, ILocales, IUserDetailed, Panel } from '@dev-fast/types';
import { NewMenu, PROJECT } from '@dev-fast/types';
import moment from 'moment';
import type { Observable } from 'rxjs';

import { daysToGo } from '@app/shared/utils';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuPanelComponent {
  @Input() set userDetailed(value: IUserDetailed | null | undefined) {
    if (!value) {
      return;
    }
    this.user = value;

    if (this.user.experience) {
      this.xpProgress = this.#barProgress(this.user.experience.xp, this.user.experience.nextLevelXp);
      this.xpBlurPosition = this.#barBlurPosition(this.user.experience.xp, this.user.experience.nextLevelXp);
      this.activityProgress = this.#barProgress(this.user.experience.xp, this.user.experience.nextLevelXp);
    }
  }

  readonly soundVolumeInput = input<number>();
  @Input() userBalance: number | undefined = 0;
  @Input() locale: ILocales | null | undefined;
  @Input() locales: ILocales[] | null | undefined;
  @Input() activeMenu: NewMenu[] | null | undefined;
  @Input() isMobile: boolean | null | undefined;
  @Input() canLevelUp: boolean | null | undefined;
  @Input() soundStatus: boolean | null | undefined;
  @Input() inventoryShortInfo: IInventoryShortInfo | undefined;
  @Input() totalBalance$!: Observable<number>;
  @Input() lockedPanels$!: Observable<Panel[]>;
  @Input() isTabletByWidth$: Observable<boolean> | undefined;

  readonly soundVolumeOutput = output<number>();
  @Output() onauthClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() soundToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() languageChange: EventEmitter<ILocales> = new EventEmitter<ILocales>();
  @Output() openMenuEvent: EventEmitter<NewMenu> = new EventEmitter<NewMenu>();
  @Output() closeMenuEvent: EventEmitter<NewMenu> = new EventEmitter<NewMenu>();

  volumeRange = signal<number>(0);
  menu = NewMenu;
  projects: typeof PROJECT = PROJECT;
  user: IUserDetailed | null = null;
  xpProgress = '';
  xpBlurPosition = '';
  activityProgress = '';

  constructor() {
    effect(
      () => {
        this.volumeRange.set(this.soundVolumeInput() ?? 0);
      },
      {
        allowSignalWrites: true,
      },
    );
    this.#sendVolumeChange();
  }

  /**
   * Открыть меню
   * @param menu - выбранное меню
   */
  openMenu(menu: NewMenu): void {
    if (menu === this.menu.LANGUAGE) {
      this.closeMenu(this.menu.MAIN_MENU);
    }
    this.openMenuEvent.emit(menu);
  }

  /**
   * Закрыть меню
   * @param menu - меню
   */
  closeMenu(menu: NewMenu): void {
    this.closeMenuEvent.emit(menu);
  }

  /**
   * Авторизация
   */
  onAuthClick(): void {
    this.onauthClick.emit();
    this.clickBack.emit();
  }

  /**
   * Переключение звука
   */
  onSoundToggle(): void {
    this.soundToggle.emit();
  }

  /**
   * Изменение громкости звука
   */
  changeVolumeRange(event: Event): void {
    const currentValue = parseInt((event.target as HTMLInputElement).value);

    this.volumeRange.set(currentValue);
  }

  /**
   * Метод для перехода на предыдущую страницу
   */
  onMenuNavigation(): void {
    this.clickBack.emit();
  }

  /**
   * Метод для отправки изменений громкости
   */
  #sendVolumeChange(): void {
    effect(() => {
      this.soundVolumeOutput.emit(this.volumeRange());
    });
  }

  /**
   * Метод для определения дней до события
   */
  #daysToGo(date: Date): number {
    return daysToGo(moment(date).unix());
  }

  /**
   * Метод для определения прогресса прогресс-бара
   * @param current - текущее значение
   * @param target - целевое значение
   * @returns - строка с прогрессом
   */
  #barProgress(current: number, target: number): string {
    const FULL = 100;

    const progress = (current * FULL) / target;
    const progressResult = progress > FULL ? FULL : progress;

    return `${progressResult}`;
  }

  /**
   * Метод для определения позиции размытия прогресс-бара
   * @param current - текущее значение
   * @param target - целевое значение
   * @returns строка с позицией размытия
   */
  #barBlurPosition(current: number, target: number): string {
    const OFFSET = 20;
    const barProgress = this.#barProgress(current, target);

    return `calc(${barProgress}% - ${OFFSET}px)`;
  }
}
