import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import type { IP2pDepositingItemV2 } from '@dev-fast/types';
import { OrderStatusEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, filter, interval, map, of, startWith, switchMap, takeUntil, timer } from 'rxjs';

import { ReplaceAvatarHashPipeModule } from '@app/shared/pipes';
import { UserAvatarComponent } from '@app/ui/components/index';

import { TradeResultsComponent } from './trade-results/trade-results.component';

@Component({
  standalone: true,
  selector: 'app-proceed-with-trade',
  templateUrl: './proceed-with-trade.component.html',
  styleUrls: ['./proceed-with-trade.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MatIconModule, DatePipe, NgClass, ReplaceAvatarHashPipeModule, UserAvatarComponent, TradeResultsComponent],
})
export class ProceedWithTradeComponent {
  tradeOffer = input.required<IP2pDepositingItemV2>();
  isMobileView = input<boolean>(false);
  status = input<OrderStatusEnum>();

  closeModal = output();

  readonly isTradeCompleted = signal(false);

  readonly timeLeft = toSignal(
    of(true).pipe(
      switchMap(() => {
        const timeLeft = 300000 + Date.now();

        return interval(1000).pipe(
          startWith(timeLeft - Date.now()),
          map(() => timeLeft - Date.now()),
          takeUntil(timer(timeLeft - Date.now())),
        );
      }),
    ),
  );

  readonly offerTimer = toSignal(
    combineLatest([toObservable(this.tradeOffer)]).pipe(
      map(([depositingItem]: [IP2pDepositingItemV2]) => depositingItem),
      filter(({ nextStatusAt }) => DateTime.fromISO(nextStatusAt).toLocal().toMillis() > DateTime.local().toMillis()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis()),
          map(() => DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis()),
          takeUntil(timer(DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis())),
        ),
      ),
    ),
  );

  readonly tradeTimer = computed(() => {
    const time = this.offerTimer();
    const isTradeCompleted = this.isTradeCompleted();

    if (!time || isTradeCompleted) {
      return;
    }

    return time;
  });

  readonly isTradeValid = computed(() => {
    const time = this.offerTimer();
    const isTradeCompleted = this.isTradeCompleted();

    return isTradeCompleted || (time && time > 1000);
  });

  readonly OrderStatusEnum = OrderStatusEnum;

  get buyerAvatarHash(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamAvatar;
  }

  get buyerSteamName(): string {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return '';
    }

    return tradeOffer.order.buyerSteamName;
  }

  get buyerSteamLevel(): number {
    const tradeOffer = this.tradeOffer();
    if (!tradeOffer) {
      return 0;
    }

    return tradeOffer.order.buyerSteamLevel;
  }
}
