@if (depositingItem(); as depositingItem) {
  <div class="wrapper">
    <ng-scrollbar class="tw-h-full tw-w-full">
      <div class="scroll-wrapper tw-flex tw-flex-col tw-w-fit tw-mx-auto">
        <mat-tab-group
          dynamicHeight
          class="tabs-default semi-round-tabs"
          [class.hide-tabs]="isMobileView() || SIHTradeInProgress()"
          [(selectedIndex)]="activeTabIndex"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-sm">
                <div>{{ 'P2P_WIDGETS.SIH_TRADE.MANUALLY_TAB' | translate }}</div>
                @if (offerTimer(); as timer) {
                  <div class="tw-text-yellow-500">{{ timer | date: dateFormat() }}</div>
                } @else {
                  <div class="tw-text-red-500">{{ 0 | date: 'mm:ss' }}</div>
                }
              </div>
            </ng-template>
            <app-p2p-manual-trade
              class="tw-block"
              [class.container-mobile-view]="isMobileView()"
              [depositingItem]="depositingItem"
              [isMobileView]="isMobileView()"
              (openAutoTradeTab)="activeTabIndex = 1"
              (closeModal)="onClose()"
            ></app-p2p-manual-trade>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <div
                class="tw-font-normal tw-text-sm"
                [class.tw-text-blue-500]="activeTabIndex === 0"
              >
                {{ 'P2P_WIDGETS.SIH_TRADE.AUTOMATIC_TAB' | translate }}
              </div>
            </ng-template>
            <ng-template matTabContent>
              <app-p2p-sih-auto-trade
                class="tw-w-screen tw-block"
                [class.rounded]="SIHTradeInProgress()"
                [class.container-mobile-view]="isMobileView()"
                [tradeOffer]="depositingItem"
                [isMobileView]="isMobileView()"
                (closeModal)="onClose()"
                (createTradeManually)="activeTabIndex = 0"
              ></app-p2p-sih-auto-trade>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-scrollbar>
  </div>
}
