@if (sellerBanEndAt$ | async; as sellerBanEndAt) {
  <div class="placeholder-container flex fx-column fx-justify-center fx-align-center w-100">
    <mat-icon svgIcon="handcuffs"></mat-icon>
    <span>{{ 'P2P_WIDGETS.DEPOSITING.YOU_ARE_BANNED' | translate }}</span>
    <p>{{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SEND_MAIL_TO_SUPPORT' | translate }}</p>
    <app-ui-countdown
      [until]="sellerBanEndAt"
      [showHours]="true"
      [minutes]="0.92"
      [onlyText]="true"
    >
    </app-ui-countdown>
    <a (click)="openTechSupportDialog()"> {{ 'P2P_WIDGETS.DEPOSITING.CONTACT_TECH_SUPPORT' | translate }}</a>
  </div>
} @else {
  @if (permissions$ | async; as permissions) {
    @if (permissions.success) {
      <app-p2p-sell-adviser
        (goToSteamInventoryMarketTabMobile)="goToTabAndClosePanel()"
        (reqAutoSelect)="onSelectItems($event)"
      />
      @if (isLoadingKits$ | async) {
        <div class="tw-flex tw-justify-center tw-py-6">
          <mat-icon
            class="tw-animate-spin !tw-w-20 !tw-h-20 tw-text-gray-400"
            style="animation-duration: 1600ms"
            svgIcon="twin-knives"
          ></mat-icon>
        </div>
      }
      @if ({ depositing: depositing$ | async, selected: selected$ | async }; as p2pData) {
        @if (p2pData.depositing) {
          <ng-scrollbar class="container-viewport hide-scroll">
            <div class="container-content tw-flex tw-flex-col">
              @for (entity of p2pData.depositing; track trackingFunction($index, entity)) {
                <app-p2p-selling-item
                  class="tw-mb-4"
                  [depositItem]="entity"
                  @expansionAnimation
                />
              }
            </div>
          </ng-scrollbar>
        }
        @if (p2pData.selected && p2pData.selected.length) {
          <app-p2p-sell-setup-panel
            [lotDepositSettings]="lotDepositSettings"
            [overprice]="overprice$ | async"
            [userPermissions]="permissions"
            [selectedItems]="p2pData.selected"
            [selectedSum]="selectedSum$ | async"
            [selectedSumWithOverprice]="selectedSumWithOverprice$ | async"
            (sellSelected)="onSell()"
            (setupSteam)="onSetupSteam()"
            (panelClosed)="onResetSelected()"
            (setOverprice)="updateOverprice($event)"
            (itemRemoved)="removeSelectedItem($event)"
            (settingsChanged)="updateTradeSettings($event)"
            (reqAutoSelectItems)="onSelectItems($event, p2pData.selected)"
          ></app-p2p-sell-setup-panel>
        }
      }
    } @else {
      <app-ui-ghost
        class="w-100 pt-10"
        [isCompact]="true"
        [underLocale]="formatErrBtnText(permissions)"
        [actionLocale]="'P2P_SETTINGS.SETUP_TITLE'"
        (actionEmitted)="handleErrorAction(permissions)"
        @expansionAnimation
      ></app-ui-ghost>
    }
  } @else {
    <div class="tw-flex tw-justify-center tw-py-6">
      <mat-icon
        class="tw-animate-spin !tw-w-20 !tw-h-20 tw-text-gray-400"
        style="animation-duration: 1600ms"
        svgIcon="twin-knives"
      ></mat-icon>
    </div>
  }
}
