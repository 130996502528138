import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import type { IP2pDepositingItemV2 } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { ReplaceAvatarHashPipeModule } from '@app/shared/pipes';
import { UserAvatarComponent } from '@app/ui/components/index';

import { MIN_TIME_LEFT_FOR_TRADE_MS } from '../../constants';

@Component({
  standalone: true,
  selector: 'app-trade-modal-actions',
  templateUrl: './trade-modal-actions.component.html',
  styleUrls: ['./trade-modal-actions.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, TranslateModule, MatIconModule, UserAvatarComponent, ReplaceAvatarHashPipeModule],
})
export class TradeModalActionsComponent {
  depositingItem = input<IP2pDepositingItemV2>();
  offerTimer = input<number>();
  isMobileView = input<boolean>(false);

  closeModal = output();
  openTradeInfo = output();
  openAutoTradeTab = output();

  readonly minTimeLeftForTrade = MIN_TIME_LEFT_FOR_TRADE_MS;

  getBtnFillPercent(timeLeft: number): number {
    const TIME_IN_MS = 1500000; // 25min in ms
    return 100 - ((TIME_IN_MS - timeLeft) / TIME_IN_MS) * 100;
  }
}
