import type { StateClass } from 'ngxs-reset-plugin/lib/internals';

import { LanguageState } from '@app/core/language-service';
import { SeoState } from '@app/core/seo-service';
import { AccountState } from '@app/core/state/account';
import { AgreementsState } from '@app/core/state/agreements';
import { ChatState } from '@app/core/state/chat';
import { CurrencyState } from '@app/core/state/currency-store';
import { CaseBattleSelectedCasesState, GamesState } from '@app/core/state/games-store';
import { InventoryState } from '@app/core/state/inventory';
import { LayoutState } from '@app/core/state/layout';
import { LotteryState } from '@app/core/state/lottery';
import { ModalsState } from '@app/core/state/modals';
import { NotificationsState } from '@app/core/state/notifications';
import { P2pBuyingState, P2pCommonState, P2pDepositState, P2pMarketState } from '@app/core/state/p2p';
import { PaymentState, RefillState, WithdrawalState } from '@app/core/state/payment-store';
import { ReferralsState } from '@app/core/state/referrals';
import { SkinsState } from '@app/core/state/skins';
import { UserState } from '@app/core/state/user-store';

export const STATES: Array<StateClass<any>> = [
  AccountState,
  AgreementsState,
  CaseBattleSelectedCasesState,
  ChatState,
  CurrencyState,
  GamesState,
  InventoryState,
  LanguageState,
  LayoutState,
  LotteryState,
  ModalsState,
  NotificationsState,
  P2pBuyingState,
  P2pCommonState,
  P2pDepositState,
  P2pMarketState,
  PaymentState,
  ReferralsState,
  RefillState,
  SeoState,
  SkinsState,
  UserState,
  WithdrawalState,
];
