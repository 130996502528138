@if (
  {
    purchasing: purchasing$ | async,
    rwtAgree: rwtAgree$ | async
  };
  as data
) {
  <ng-scrollbar class="container-viewport hide-scroll">
    @if (data.purchasing?.length) {
      <div
        class="container-content flex fx-column pr-sm-5"
        @expansionAnimation
      >
        @for (entity of data.purchasing; track trackingFunction($index, entity)) {
          <app-p2p-purchase-item
            class="container-content__item mb-2"
            [rwtAgree]="!!data.rwtAgree"
            [purchaseItem]="entity"
            (oncheckValidation)="checkValidation()"
            @expansionAnimation
          />
        }
        <!-- *ngIf="showWarn$ | async as notified" -->
        <!-- В новом дизе нет кнопки закрытия варна, но на всякий случай оставил всю логику на месте.
        Если через какое-то время не понадобится, то можно удалить -->
        <div class="market-attention">
          <div class="warning-banner text-sm">
            <div class="warning-banner__icon"><mat-icon svgIcon="danger-sign--design"></mat-icon></div>
            <div class="warning-banner__message">
              <div class="warning-banner__message-body">
                {{ 'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div
        class="invite-container flex fx-column fx-justify-center fx-align-center w-100"
        @expansionAnimation
      >
        <mat-icon svgIcon="ghost--design"></mat-icon>
        <p>{{ 'P2P_WIDGETS.INVITE.YOU_CAN_BUY_SKINS' | translate }}</p>
        <a
          class="mobile-hide"
          (click)="goToStoreTab()"
          langRouterLink
          routerLink="store"
        >
          {{ 'P2P_WIDGETS.INVITE.MARKETPLACE' | translate }}
        </a>
        <a
          class="desktop-hide"
          (click)="goToTabAndClosePanel()"
          langRouterLink
          routerLink="store"
        >
          {{ 'P2P_WIDGETS.INVITE.MARKETPLACE' | translate }}
        </a>
      </div>
      @if (isLoadingBids$ | async) {
        <div class="tw-flex tw-justify-center tw-py-6">
          <mat-icon
            class="tw-animate-spin !tw-w-20 !tw-h-20 tw-text-gray-400"
            style="animation-duration: 1600ms"
            svgIcon="twin-knives"
          ></mat-icon>
        </div>
      }
    }
  </ng-scrollbar>
  @if (!data.rwtAgree) {
    <ng-container [formGroup]="rwtForm">
      <app-ui-accept
        formControlName="agreement"
        [labels]="agreementLabels"
        [acceptText]="'MARKET.RWT.MODAL.AGREE_BTN'"
        [checkValidation$]="checkValidation$"
        @expansionAnimation
      >
      </app-ui-accept>
    </ng-container>
  }
}
