import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Permissions } from '@dev-fast/types';

import { UserService } from '@app/core/user-service';
import { ClickOutsideModule, HasPermissionModule } from '@app/shared/directives';
import { UiIconsModule } from '@app/ui/icons';
import { PromocodeMobileComponent } from '@app/widgets/promocode';

@Component({
  selector: 'ui-promo-block',
  standalone: true,
  imports: [HasPermissionModule, UiIconsModule, PromocodeMobileComponent, ClickOutsideModule],
  templateUrl: './promo-block.component.html',
  styleUrls: ['./promo-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBlockComponent {
  readonly #user = inject(UserService);
  readonly #isUserBanned = toSignal(this.#user.isUserBanned$);
  readonly openGleamModal = output<boolean>();

  panelOpened = false;

  protected readonly permissions = Permissions;

  togglePromoPanel(): void {
    if (!this.#isUserBanned()) {
      this.panelOpened = !this.panelOpened;
    }
  }

  closePanel(): void {
    this.panelOpened = false;
  }

  onOpenGleamModal(): void {
    if (!this.#isUserBanned()) {
      this.openGleamModal.emit(true);
    }
  }
}
