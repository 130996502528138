import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { P2pBuyingService } from '@core/p2p-services/p2p-buying.service';
import { P2pDepositService } from '@core/p2p-services/p2p-deposit.service';
import type {
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pDepositSettings,
  ISteamStoreInventory,
  IUserP2pPermissionInterface,
} from '@dev-fast/types';
import { SteamErrorsEnum, SteamErrorsEnumLocales } from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { Subject, takeUntil } from 'rxjs';

import { expansionAnimation } from '../shared';
import { P2pSellService } from './p2p-sell.service';

@Component({
  selector: 'app-p2p-sell',
  templateUrl: './p2p-sell.component.html',
  styleUrls: ['./p2p-sell.component.scss'],
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSellComponent {
  readonly #p2pDepositService = inject(P2pDepositService);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #moduleService = inject(P2pSellService);
  readonly sellerBanEndAt$: Observable<number | null> = this.#moduleService.sellerBanEndAt$;
  readonly permissions$: Observable<IUserP2pPermissionInterface> = this.#moduleService.p2pPermissions$;
  readonly steamInventoryItems$: Observable<ISteamStoreInventory[]> = this.#p2pDepositService.steamInventoryItems$;
  // Lot setup template
  readonly overprice$: Observable<number> = this.#p2pDepositService.overprice$;
  readonly selected$: Observable<IP2pDepositItem[]> = this.#p2pDepositService.selected$();
  readonly selectedSum$: Observable<number> = this.#p2pDepositService.selectedSum$();
  readonly selectedSumWithOverprice$: Observable<number> = this.#p2pDepositService.selectedSumWithOverprice$();

  private destroy$: Subject<void> = new Subject();

  depositing$: Observable<IP2pDepositingItem[]> = this.#p2pDepositService.depositing$;
  lotDepositSettings!: IP2pDepositSettings;
  steamErrorsEnum = SteamErrorsEnum;
  //На макетах только "Удалить все" из экшенов осталось, но на всякий случай методы паузы\старта сохранены
  // public isItemsOnPause$: Observable<boolean>= this.p2pDepositService.isItemsOnPause$;
  isLoadingKits$: Observable<boolean> = this.#p2pDepositService.isLoadingKits$;

  constructor() {
    this.#p2pDepositService.additionalSettings$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value && value.market) {
        this.lotDepositSettings = {
          fastDelivery: value.fastDelivery,
          autoApprove: value.market.autoApprove,
          tradesDuration: value.market.tradesDuration,
        };
      }
    });
  }

  // На макетах только "Удалить все" из экшенов осталось, но на всякий случай методы паузы\старта сохранены
  // public onStopAvailableItems() {
  //   this.p2pDepositService.stop();
  // }
  // public onPauseAvailableItems() {
  //   this.p2pDepositService.pause();
  // }
  // public onResumeAvailableItems() {
  //   this.p2pDepositService.resume();
  // }

  onSelectItems(currency: number, selectedItems?: IP2pDepositItem[]): void {
    const selectionSum: number = currency * 100;
    const selectedIds: string | undefined = selectedItems?.map(({ steamInventoryId }) => steamInventoryId).join();

    this.#p2pDepositService.getDepositAutoSelection({
      selectionSum,
      selectedIds,
    });
  }

  onSell(): void {
    this.#p2pDepositService.depositSelected();
  }

  onResetSelected(): void {
    this.#p2pDepositService.resetSelected();
  }

  onUpdateSelected(items: IP2pDepositItem[]): void {
    this.#p2pDepositService.updateSelected(items);
  }

  removeSelectedItem(item: IP2pDepositItem): void {
    this.#p2pDepositService.removeDepositItem(item);
  }

  trackingFunction(index: number, item: IP2pDepositingItem): number {
    return item.id;
  }

  openTechSupportDialog(): void {
    this.#moduleService.openSupportModal();
  }

  onSetupSteam(): void {
    this.#moduleService.openSteamSetupModal();
  }

  updateOverprice(newOverprice: number): void {
    this.#p2pDepositService.updateOverprice(newOverprice);
  }

  goToTabAndClosePanel(): void {
    this.#p2pDepositService.closeMarketPanel();
  }

  updateTradeSettings(newValue: IP2pDepositSettings): void {
    this.lotDepositSettings = newValue;
    this.#p2pDepositService.changeAdditionalSettings({
      fastDelivery: this.lotDepositSettings.fastDelivery,
      market: {
        autoApprove: this.lotDepositSettings.autoApprove,
        tradesDuration: this.lotDepositSettings.tradesDuration,
      },
    });
  }

  formatErrBtnText(permissions: IUserP2pPermissionInterface): string {
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }

  handleErrorAction(permissions: IUserP2pPermissionInterface | null): void {
    if (!permissions) {
      return;
    }
    const errorMsg = permissions.canTrade?.error || permissions.canSteamAPI?.error || permissions.error;
    switch (errorMsg) {
      case SteamErrorsEnum.UNBINDING_STEAM:
        window.open('/account/main', '_blank');
        break;
      case SteamErrorsEnum.STEAM_GUARD:
        window.open('https://store.steampowered.com/mobile', '_blank');
        break;
      case SteamErrorsEnum.PRIVATE_INVENTORY:
        window.open('https://steamcommunity.com/my/edit/settings', '_blank');
        break;
      case SteamErrorsEnum.STEAM_TRADE_BAN:
      case SteamErrorsEnum.STEAM_GUARD_HOLD:
        break;
      case SteamErrorsEnum.INVALID_TRADELINK:
      case SteamErrorsEnum.NO_API_KEY:
      default:
        this.onSetupSteam();
        break;
    }
  }
}
