import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { routes } from './root.routes';

const scrollExcludes: string[] = ['case_category'];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      get scrollPositionRestoration() {
        if (typeof document === 'undefined') {
          return 'enabled';
        }
        const params = new URLSearchParams(document.location.search);
        const disabledScroll = scrollExcludes.some((param) => params.get(param));
        if (disabledScroll) {
          return 'disabled';
        }
        return 'enabled';
      },
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
