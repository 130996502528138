import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import type { IP2pDepositingItemV2 } from '@dev-fast/types';
import { OrderStatusEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { expansionAnimation } from '@app/games/lib/shared/animations';
import { SIH_LINK } from '@app/widgets/sih';

@Component({
  standalone: true,
  selector: 'app-creating-trade-panel',
  templateUrl: './creating-trade-panel.component.html',
  styleUrls: ['./creating-trade-panel.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expansionAnimation],
  providers: [provideAnimations()],
  imports: [TranslateModule, MatIconModule],
})
export class CreatingTradePanelComponent {
  tradeOffer = input<IP2pDepositingItemV2>();
  status = input<OrderStatusEnum>();

  createTradeManually = output();
  closeModal = output();
  solveProblem = output();

  readonly sihLink = SIH_LINK;
  readonly OrderStatusEnum = OrderStatusEnum;
}
