<div class="balance__container">
  <ng-container *moduleIsOn="'inventory'">
    <a
      (click)="onOpenInventory()"
      class="balance__container__skins"
    >
      <mat-icon
        class="menu-icon"
        svgIcon="automate-gun"
      />
      <span>{{ inventoryCount }}</span>
    </a>
  </ng-container>
  <app-currency
    class="balance-panel__coins tw-cursor-pointer"
    [value]="userBalance"
    (click)="openTransactions(transactionWayEnum.REFILL)"
  ></app-currency>
  <div class="balance-panel">
    <button
      (click)="openTransactions(transactionWayEnum.REFILL)"
      class="refill-btn btn btn-inversion-yellow--design"
    >
      <span class="refill-btn__label">{{ 'BALANCE_REFILL.LINK.REFILL' | translate | titlecase }}</span>

      <span
        class="refill-bonus"
        [matTooltipClass]="['gray-500', 'balance-block']"
        [matTooltip]="'BALANCE_REFILL.BONUS_TOOLTIP' | translate"
        [matTooltipPosition]="'below'"
      >
        {{ bonusValue }}
      </span>
    </button>

    <button
      class="withdrawal-btn btn btn-outline-blue--design"
      (click)="openTransactions(transactionWayEnum.WITHDRAWAL)"
    >
      <mat-icon
        svgIcon="minus--bold"
        class="withdrawal-btn__icon"
      ></mat-icon>
    </button>
  </div>
</div>
