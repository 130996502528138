import type { IconConfig } from '@dev-fast/types';

// иконки чтобы в приложении по шаблонам не заниматься заменой а в момент регистрации модулем прост их перезаменить
export const customIcons: IconConfig[] = [
  {
    name: 'text-domain',
    path: 'assets/img/mat-icons/logos/domain/csgofast.svg',
  },
  {
    name: 'img-domain',
    path: 'assets/img/mat-icons/logos/mirror/csgo.svg',
  },
  {
    name: 'coin-primary',
    path: 'assets/img/coins/fastcoin.svg',
  },
  {
    name: 'coin-secondary',
    path: 'assets/img/coins/fastcoin.svg',
  },
  {
    name: 'twin-knives',
    path: 'assets/img/knives/knives.svg',
  },
];
